<template>
  <NuxtLayout name="account">
    <p class="main-title">Linked Accounts</p>
    <div class="grid grid-cols-2 gap-4 my-l">
      <AppLinkedAccount
        v-for="(details, key) in integrations"
        :key="key"
        :service="key"
      />
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import integrations from '@respell/integrations';
import AppLinkedAccount from '~/components/app/AppLinkedAccount.vue';
</script>
